import(/* webpackMode: "eager" */ "/app/app/components/common/FAQ.js");
import(/* webpackMode: "eager" */ "/app/app/components/common/SliderWrapper.js");
import(/* webpackMode: "eager" */ "/app/app/components/pages/home/FormNewsletter.js");
import(/* webpackMode: "eager" */ "/app/app/components/pages/shop/ProductPreview.js");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-find-gift.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-banner.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/common/section-title-icon.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/common/card-preview.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-concept.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-latest-posts.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-post-card.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-comments.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/categories-card.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-categories.module.scss");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-newsletter.module.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
import(/* webpackMode: "eager" */ "/app/app/styles/components/pages/home/home-special-offers.module.scss")